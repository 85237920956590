import Immutable from 'immutable';
import * as types from './MyInformation.types';

const initialState = {
    information: null,
    fetchingInformation: false,
    history: null,
    states: [],
    savingInformation: false,
    savingPassword: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information'], action.payload.customer)
                .setIn(['states'], action.payload.states)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.SAVE_INFORMATION:
            return state.setIn(['savingInformation'], true).toJS();

        case types.SAVE_INFORMATION_SUCCESS:
            return state.setIn(['savingInformation'], false).toJS();

        case types.SAVE_INFORMATION_ERROR:
            return state.setIn(['savingInformation'], false).toJS();

        case types.SAVE_PASSWORD:
            return state.setIn(['savingInformation'], true).toJS();

        case types.SAVE_PASSWORD_SUCCESS:
            return state.setIn(['savingInformation'], false)
                .setIn(['information', 'old_password'], '')
                .setIn(['information', 'new_password'], '')
                .setIn(['information', 'confirm_password'], '')
                .toJS();

        case types.SAVE_PASSWORD_ERROR:
            return state.setIn(['savingInformation'], false).toJS();

        case types.SET_FIELD:
            return state.setIn(['information', action.payload.field], action.payload.value).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}