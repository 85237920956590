import React from 'react';
import { Link } from 'react-router-dom';

const StartNow = (props) => {
    return (
        <div className="start-now-container content-container">
            <div className="main-container">
                <div className="title">Are you Ready to Customize your World?</div>
                <div className="button-container">
                    <Link to="/">Start Now!</Link>
                </div>
            </div>
        </div>
    );
}

export default StartNow;
