import React from 'react';

const Information = (props) => {
    return (
        <div className="information-container content-container">
            <div className="title">Privacy</div>
        </div>
    );
}

export default Information;
