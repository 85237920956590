import React, { useEffect } from 'react';
import { initCanvas } from './../CanvasActions';

const Editor = (props) => {
    /* componentDidMount */
    useEffect(() => {
        initCanvas();
    }, []);

    return (
        <div className="editor-container">
            <div className="editor-header">
                <div className="product">
                    <div className="product-name">Unisex Heavy Cotton Tee</div>
                    <div className="product-subname">Gildan 5000</div>
                </div>
                <div className="buttons-container">
                    <button className="edit-button">Edit</button>
                    <button className="preview-button">Preview</button>
                    <button className="save-button">Save Product</button>
                </div>
            </div>
            <div className="controls">
                <div className="tabs">
                    <div className="tab">PRODUCT</div>
                    <div className="tab active">DESIGN</div>
                </div>
                <div className="tools">
                    <div className="tool-element">
                        <i className="fa-solid fa-upload"></i> Choose File
                    </div>
                    <div className="tool-element">
                        <i className="fa-regular fa-image"></i> My Library
                    </div>
                    <div className="tool-element">
                        <i className="fa-solid fa-font"></i> Add Text
                    </div>
                    <div className="tool-element">
                        <i className="fa-regular fa-face-smile"></i> Add Clipart
                    </div>
                </div>
            </div>
            <div className="designer">
                <div className="sides-buttons">
                    <button className="active">Front Side</button>
                    <button>Back Side</button>
                </div>
                <div className="tools">

                </div>
                <div className="designer-canvas">
                    <canvas id="fabric-canvas" />
                </div>
            </div>
            <div className="footer">
                <div className="back-button">
                    <button>Back</button>
                </div>
                <div className="footer-controls">
                    <div className="minus">
                        <i className="fa-solid fa-minus"></i>
                    </div>
                    <div className="zoom">
                        <select>
                            <option value="100">100%</option>
                        </select>
                    </div>
                    <div className="plus">
                        <i className="fa-solid fa-plus"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Editor;
