import React from 'react';
import { Link } from 'react-router-dom';

const Popular = (props) => {
    return (
        <div className="popular-container content-container">
            <div className="title">Popular Products</div>
            <div className="categories">
                <div className="category">
                    <div className="image">
                        <img src="https://ac-cbi.s3.amazonaws.com/css/product1-test.png" alt="Product" />
                    </div>
                    <div className="name">T-Shirts & Tank Tops</div>
                    <div className="price">Starting from $ 9.95</div>
                </div>
                <div className="category">
                    <div className="image">
                        <img src="https://ac-cbi.s3.amazonaws.com/css/product2-test.png" alt="Product" />
                    </div>
                    <div className="name">Drinkware</div>
                    <div className="price">Starting from $ 4.95</div>
                </div>
                <div className="category">
                    <div className="image">
                        <img src="https://ac-cbi.s3.amazonaws.com/css/product3-test.png" alt="Product" />
                    </div>
                    <div className="name">Poster & Canvas Prints</div>
                    <div className="price">Starting from $ 9.95</div>
                </div>
                <div className="category">
                    <div className="image">
                        <img src="https://ac-cbi.s3.amazonaws.com/css/product4-test.png" alt="Product" />
                    </div>
                    <div className="name">Bags</div>
                    <div className="price">Starting from $ 4.95</div>
                </div>
                <div className="category">
                    <div className="image">
                        <img src="https://ac-cbi.s3.amazonaws.com/css/product5-test.png" alt="Product" />
                    </div>
                    <div className="name">Notebooks</div>
                    <div className="price">Starting from $ 11.60</div>
                </div>
                <div className="category">
                    <div className="image">
                        <img src="https://ac-cbi.s3.amazonaws.com/css/product1-test.png" alt="Product" />
                    </div>
                    <div className="name">T-Shirts & Tank Tops</div>
                    <div className="price">Starting from $ 9.95</div>
                </div>
            </div>
            <div className="show-button-container">
                <Link to="/" className="show-button">Show More</Link>
            </div>
        </div>
    );
}

export default Popular;
