import { createSelector } from 'reselect';

export const getComponentState = state => state.signUp;

export const getForm = createSelector(
    getComponentState,
    (state) => state.form
);
export const getRegistering = createSelector(
    getComponentState,
    (state) => state.registering
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);