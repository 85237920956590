import React from 'react';

const Configuration = (props) => {
    if (props.fetchingElement) {
        return (
            <div className="configuration-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }

        props.setField(field, value);
    };

    return (
        <div className="configuration-container">
            <div className="fields">
                <h2>Main Configuration</h2>
                <div className="field">
                    <div className="label">Tax (%) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.tax} onChange={handleSetField.bind(this, 'tax')} placeholder="Tax" />
                </div>
                <div className="field">
                    <div className="label">Shipping Markup (%) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.shipping_percentage} onChange={handleSetField.bind(this, 'shipping_percentage')} placeholder="Shipping Markup" />
                </div>
                {/* <h2>Mail Size Checker</h2>
                <div className="field">
                    <div className="label">Minimum Length (inches) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.minimum_length} onChange={handleSetField.bind(this, 'minimum_length')} placeholder="Minimum Length" />
                </div>
                <div className="field">
                    <div className="label">Maximum Length (inches) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.maximum_length} onChange={handleSetField.bind(this, 'maximum_length')} placeholder="Maximum Length" />
                </div>
                <div className="field">
                    <div className="label">Minimum Height (inches) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.minimum_height} onChange={handleSetField.bind(this, 'minimum_height')} placeholder="Minimum Height" />
                </div>
                <div className="field">
                    <div className="label">Maximum Length (inches) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.maximum_height} onChange={handleSetField.bind(this, 'maximum_height')} placeholder="Maximum Length" />
                </div>
                <div className="field">
                    <div className="label">Minimum Thickness (inches) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.minimum_thickness} onChange={handleSetField.bind(this, 'minimum_thickness')} placeholder="Minimum Thickness" />
                </div>
                <div className="field">
                    <div className="label">Maximum Thickness (inches) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.maximum_thickness} onChange={handleSetField.bind(this, 'maximum_thickness')} placeholder="Maximum Thickness" />
                </div>
                <h2>Weight Limits</h2>
                <div className="field">
                    <div className="label">Maximum Weight 1-5,000 pieces (oz/piece) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.maximum_weight1} onChange={handleSetField.bind(this, 'maximum_weight1')} placeholder="Maximum Weight" />
                </div>
                <div className="field">
                    <div className="label">Maximum Weight 5,001-1,000,000 pieces (oz/piece) <span className="required">*</span>:</div>
                    <input type="text" value={props.item.maximum_weight2} onChange={handleSetField.bind(this, 'maximum_weight2')} placeholder="Maximum Weight" />
                </div> */}
            </div>
            <div className="buttons-container">
                <div className="button-container">
                    <button className="save-button admin-button" onClick={props.saveElement} disabled={props.saving}>
                        {props.saving ? <i className="fas fa-circle-notch fa-spin" /> : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Configuration;
