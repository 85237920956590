import React from 'react';
import { Link } from 'react-router-dom';

const Story = (props) => {
    return (
        <div className="story-container">
            <div className="content-container">
                <div className="title">
                    <span>Design</span> your own <strong>story</strong>
                </div>
                <div className="subtitle">
                    Our mission is to bring joy to your life, one custom product at a time.
                </div>
                <div className="description">
                    <p>
                        Just like the hummingbird, we believe in the nectar of life and the transformative power of creativity.
                    </p>
                    <p>
                        With our <strong>easy-to-use platform</strong>, you can turn your ideas into reality and create products that 
                        truly reflect your personal style and story. So let's get started on making memories that will last a lifetime, 
                        with Custom <strong>Brand It</strong>.
                    </p>
                </div>
                <Link to="/" className="about-button">About Us</Link>
                <img className="banner" src="https://ac-cbi.s3.amazonaws.com/css/story-banner.png" alt="Custom Brand It" />
                <img className="hummingbird" src="https://ac-cbi.s3.amazonaws.com/css/hummingbird.png" alt="Custom Brand It" />
            </div>
        </div>
    );
}

export default Story;
