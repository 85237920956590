import React from 'react';
import Pagination from './../../Pagination';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';

const Table = (props) => {
    if (props.actionType !== 'list') {
        return null;
    }

    const handleExportExcelModal = (id) => {
        props.exportExcel(id);
    };

    // const handleExportPdfModal = (id) => {
    //     props.exportPdf(id);
    // };

    const handleArtworksModal = (id) => {
        props.setIdModal(id);
        props.showArtworksModal();
    };

    const handleGeneratePdfModal = (id) => {
        props.setIdModal(id);
        props.showGeneratePdfModal();
    };

    const handleDeleteModal = (id) => {
        props.setIdModal(id);
        props.showDeleteModal();
    };

    const handleChangeStatus = (id, event) => {
        props.changeStatus(id, event.target.value);
        props.addPendingSaveId(id);
    };

    const handleCreateScTicketModal = (id) => {
        props.setIdModal(id);
        props.showCreateScTicketModal();
    };

    const renderElements = () => {
        if (props.fetchingElements) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (props.items.length === 0) {
            return (
                <tr>
                    <td colSpan="10">No results</td>
                </tr>
            );
        }

        return props.items.map((item) => {
            let editIcon = (
                <div className="action-icon">
                    <Tooltip title="Order Details" position="bottom" arrow size="small" className="action-tooltip">
                        <Link to={'/admin/orders/details/' + item.id}><i className="fa-solid fa-rectangle-list"></i></Link>
                    </Tooltip>
                </div>
            );
            let excelIcon = (
                <div className="action-icon">
                    <Tooltip title="Generate Route Summary" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-file-excel" onClick={handleExportExcelModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            let artworksClasses = ['fa-solid fa-file-arrow-down'];
            if (item.artworks.length > 0) {
                artworksClasses.push('has-files');
            } else {
                artworksClasses.push('no-files');
            }

            let generatePdfIcon = (
                <div className="action-icon">
                    <Tooltip title="Generate Facing Slips" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-file-pdf" onClick={handleGeneratePdfModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            let artworksIcon = (
                <div className="action-icon">
                    <Tooltip title="Artworks" position="bottom" arrow size="small" className="action-tooltip">
                        <i className={artworksClasses.join(' ')} onClick={handleArtworksModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            let deleteIcon = (
                <div className="action-icon">
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fa-solid fa-trash" onClick={handleDeleteModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            let convertIcon = (
                <div className="action-icon">
                    <Tooltip title="Create SC Ticket" position="bottom" arrow size="small" className="action-tooltip">
                        <i class="fa-solid fa-arrow-right-to-bracket" onClick={handleCreateScTicketModal.bind(this, item.id)}></i>
                    </Tooltip>
                </div>
            );

            let saveFunc = null;
            let checkClass = ['check-container'];
            const ind = props.pendingSaveIds.findIndex((savedId) => parseInt(savedId) === parseInt(item.id));
            if (ind !== -1) {
                checkClass.push('active');
                saveFunc = props.saveStatus.bind(this, item.id, item.order_status_id);
            }

            let savingIcon = null;
            if (parseInt(props.savingId) === parseInt(item.id)) {
                savingIcon = <i className="fas fa-circle-notch fa-spin" />;
            }

            return (
                <tr>
                    <td className="id-column">{item.id}</td>
                    <td className="customer-column">
                        <div className="name">{item.customer_name}</div>
                        <div className="description">
                            <div className="description-item">
                                <div className="label">Order #:</div>
                                <div className="value">{item.id}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Customer:</div>
                                <div className="value">{item.customer_name}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Title:</div>
                                <div className="value">{item.name}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Ordered at:</div>
                                <div className="value">{dateToTimezone(item.order_datetime, 'MM/DD/YYYY')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Total Addresses:</div>
                                <div className="value">{numeral(item.total_addresses).format('0,0')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">Total Cost:</div>
                                <div className="value">{numeral(item.total_cost).format('$0,0.00')}</div>
                            </div>
                            <div className="description-item">
                                <div className="label">SC#:</div>
                                <div className="value">
                                    <a href={'https://www.stagecoachview.com/tracking/' + item.sc_number} target="_blank" rel="noopener noreferrer">{item.sc_number}</a>
                                </div>
                            </div>
                            <div className="description-actions">
                                {convertIcon}
                                {editIcon}
                                {excelIcon}
                                {generatePdfIcon}
                                {artworksIcon}
                                {deleteIcon}
                            </div>
                        </div>
                    </td>
                    <td className="title-column">{item.name}</td>
                    <td className="status-column">
                        <select value={item.order_status_id} onChange={handleChangeStatus.bind(this, item.id)} >
                            {props.information.statuses.map((status) => <option value={status.id}>{status.name}</option>)}
                        </select>
                        <div className={checkClass.join(' ')} onClick={saveFunc}>
                            <i className="fa-solid fa-circle-check"></i>
                        </div>
                        {savingIcon}
                    </td>
                    <td className="ordered-at-column">{dateToTimezone(item.order_datetime, 'MM/DD/YYYY')}</td>
                    <td className="total-addresses-column">{numeral(item.total_addresses).format('0,0')}</td>
                    <td className="total-cost-column">{numeral(item.total_cost - item.total_cost_discounted).format('$0,0.00')}</td>
                    <td className="sc-number-column">
                        <a href={'https://www.stagecoachview.com/tracking/' + item.sc_number} target="_blank" rel="noopener noreferrer">{item.sc_number}</a>
                    </td>
                    <td className="actions-column">
                        <div className="actions-container">
                            {convertIcon}
                            {editIcon}
                            {excelIcon}
                            {generatePdfIcon}
                            {artworksIcon}
                            {deleteIcon}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const handleSort = (column, type) => {
        props.setSort(column, type, props.type);
    };

    const renderHeader = (label, field) => {
        let sortType = 'asc';
        let sortIcon = <i className="fas fa-sort" />;
        if (props.sort.column === field) {
            if (props.sort.type === 'desc') {
                sortIcon = <i className="fas fa-sort-down" />;
                sortType = 'asc';
            } else {
                sortIcon = <i className="fas fa-sort-up" />;
                sortType = 'desc';
            }
        }

        return <div className="sort-item" onClick={handleSort.bind(this, field, sortType)}>{label} {sortIcon}</div>
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr className="header-row">
                        <th className="id-column">{renderHeader('Order #', 'id')}</th>
                        <th className="customer-column">{renderHeader('Customer', 'customer_name')}</th>
                        <th className="title-column">{renderHeader('Title', 'name')}</th>
                        <th className="title-column">{renderHeader('Status', 'status_name')}</th>
                        <th className="ordered-at-column">{renderHeader('Ordered at', 'order_datetime')}</th>
                        <th className="total-addresses-column">{renderHeader('Total Addresses', 'total_addresses')}</th>
                        <th className="total-cost-column">{renderHeader('Total Cost', 'total_cost')}</th>
                        <th className="sc-number-column">{renderHeader('SC#', 'sc_number')}</th>
                        <th className="actions-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderElements()}
                </tbody>
            </table>
            <Pagination
                pagination={props.pagination}
                setActivePage={props.setActivePage}
            />
        </div>
    );
}

export default Table;
