import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './ForgotPassword.actions';
import * as selectors from './ForgotPassword.selectors';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import './ForgotPassword.scss';

const ForgotPassword = (props) => {
    /* State to props */
    const sending = useSelector(selectors.getSending);
    const sent = useSelector(selectors.getSent);
    const form = useSelector(selectors.getForm, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const send = useCallback((history) => dispatch(actions.send(history)), [dispatch]);
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');
        
        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <div className="forgot-password-container">
            <Helmet>
                <title>Forgot Password - Custom Brand It</title>
            </Helmet>
            <Favicon url="https://ac-cbi.s3.amazonaws.com/favicon.ico" />
            <Form
                form={form}
                sending={sending}
                sent={sent}
                history={props.history}
                setField={setField}
                send={send}
            />
        </div>
    );
};

export default ForgotPassword;
