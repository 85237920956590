import axios from 'axios';
import * as types from './MyOrderDetails.types';
// import * as selectors from './MyOrderDetails.selectors';
import { validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchOrder = (id) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ORDER, payload: null });
    axios.get(apiUrl + '/orders/' + id + '?expand=productOptions,campaignDrops,billingAddress,zipCodes,routes',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_ORDER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_ORDER_ERROR));
        });
}

export const setIdModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_ID_MODAL,
        payload: id
    });
}

export const setTypeModal = (id) => (dispatch) => {
    dispatch({
        type: types.SET_TYPE_MODAL,
        payload: id
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history,
    });
}

export const resetState = (value) => dispatch => {
    dispatch({
        type: types.RESET_STATE,
        payload: value,
    });
}