import React from 'react';
import { Link } from 'react-router-dom';

const Sales = (props) => {
    return (
        <div className="sales-container content-container">
            <div className="main-container">
                <div className="title">
                    Are you a <strong>business</strong> seeking customized solutions on a <strong>large scale</strong>?
                </div>
                <div className="button-container">
                    <Link to="/">Talk to Sales</Link>
                </div>
            </div>
        </div>
    );
}

export default Sales;
