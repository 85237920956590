import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import Menu from './Menu';
import ArtworksModal from './ArtworksModal';
import './MyOrders.scss';
import { stringifyEqualityCheck } from './../../utils';
import * as selectors from './MyOrders.selectors';
import * as actions from './MyOrders.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import * as dialog from './../common/dialog';

const MyOrdersContainer = (props) => {
    /* State to props */
    const orders = useSelector(selectors.getOrders, stringifyEqualityCheck);
    const fetchingOrders = useSelector(selectors.getFetchingOrders);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const search = useSelector(selectors.getSearch);
    const searchType = useSelector(selectors.getSearchType);
    const artworks = useSelector(selectors.getArtworks, stringifyEqualityCheck);
    const uploadingArtworks = useSelector(selectors.getUploadingArtworks);
    const idModal = useSelector(selectors.getIdModal);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchOrders = useCallback(() => dispatch(actions.fetchOrders()), [dispatch]);
    const setActivePage = useCallback((value) => dispatch(actions.setActivePage(value)), [dispatch]);
    const setSort = useCallback((column, type, orderType) => dispatch(actions.setSort(column, type, orderType)), [dispatch]);
    const setSearch = useCallback((value) => dispatch(actions.setSearch(value)), [dispatch]);
    const setSearchType = useCallback((value) => dispatch(actions.setSearchType(value)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const addArtwork = useCallback((file) => dispatch(actions.addArtwork(file)), [dispatch]);
    const removeArtwork = useCallback((index) => dispatch(actions.removeArtwork(index)), [dispatch]);
    const clearArtworks = useCallback((index) => dispatch(actions.clearArtworks(index)), [dispatch]);
    const uploadArtworks = useCallback(() => dispatch(actions.uploadArtworks()), [dispatch]);
    const setArtworks = useCallback((artworks) => dispatch(actions.setArtworks(artworks)), [dispatch]);
    const deleteArtwork = useCallback((id) => dispatch(actions.deleteArtwork(id)), [dispatch]);
    const showArtworksModal = useCallback(() => dispatch(dialog.actions.showDialog('artworks-modal')), [dispatch]);
    const hideArtworksModal = useCallback(() => dispatch(dialog.actions.hideDialog('artworks-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchOrders();

        return () => {
            resetState();
        };
    }, [fetchOrders, setHistory, resetState, props.history]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>My Orders - Custom Brand It</title>
            </Helmet>
            <div className="my-orders-container">
                <div className="content-container">
                    <Menu
                        history={props.history}
                    />
                    <Table
                        orders={orders}
                        fetchingOrders={fetchingOrders}
                        pagination={pagination}
                        sort={sort}
                        search={search}
                        searchType={searchType}
                        setActivePage={setActivePage}
                        setSort={setSort}
                        setSearch={setSearch}
                        setSearchType={setSearchType}
                        showArtworksModal={showArtworksModal}
                        setIdModal={setIdModal}
                        setArtworks={setArtworks}
                    />
                </div>
                <ArtworksModal
                    orders={orders}
                    idModal={idModal}
                    artworks={artworks}
                    uploadingArtworks={uploadingArtworks}
                    hideArtworksModal={hideArtworksModal}
                    setIdModal={setIdModal}
                    addArtwork={addArtwork}
                    removeArtwork={removeArtwork}
                    clearArtworks={clearArtworks}
                    uploadArtworks={uploadArtworks}
                    deleteArtwork={deleteArtwork}
                />
            </div>
        </Layout>
    );
};

export default MyOrdersContainer;
