import { createSelector } from 'reselect';

export const getComponentState = state => state.myInformation;

export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getStates = createSelector(
    getComponentState,
    (state) => state.states
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetchingInformation
);
export const getSavingInformation = createSelector(
    getComponentState,
    (state) => state.savingInformation
);
export const getSavingPassword = createSelector(
    getComponentState,
    (state) => state.savingPassword
);
export const getHistory = createSelector(
    getComponentState,
    (state) => state.history
);