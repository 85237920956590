import React from 'react';

const Information = (props) => {
    return (
        <div className="information-container content-container">
            <div className="title">Notification of Site Terms &amp; Conditions</div>
            <p>Terms</p>
        </div>
    );
}

export default Information;
