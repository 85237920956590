import React from 'react';
import { Dialog } from './../../common/dialog';
import numeral from 'numeral';

const RouteDetailsModal = React.memo((props) => {
    if (props.typeModal == null) {
        return null;
    }

    const hideHandler = () => {
        props.setIdModal(null);
        props.setTypeModal(null);
        props.hideRouteDetailsModal();
    };

    let title = null;
    let routeIdItem = null;
    let cridIdItem = null;
    if (props.typeModal === 'zip-code') {
        title = <h1>Zip Code Information</h1>;
    } else if (props.typeModal === 'route') {
        title = <h1>Route Information</h1>;
        routeIdItem = (
            <div className="info-item">
                <div className="label">Route ID:</div>
                <div className="value">{props.idModal.zip_crid}</div>
            </div>
        );
        cridIdItem = (
            <div className="info-item">
                <div className="label">CRID ID:</div>
                <div className="value">{props.idModal.crid_id}</div>
            </div>
        );
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm admin-button" onClick={hideHandler}>Close</button>,
    ];
    return (
        <Dialog
            name="route-details-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="admin-orders-route-dialog"
        >
            <div className="admin-orders-route-modal-container">
                {title}
                {routeIdItem}
                <div className="info-item">
                    <div className="label">Zip Code:</div>
                    <div className="value">{props.idModal.zip_code}</div>
                </div>
                {cridIdItem}
                <div className="info-item">
                    <div className="label">Residential Addresses:</div>
                    <div className="value">{numeral(props.idModal.residential_addresses).format('0,0')}</div>
                </div>
                <div className="info-item">
                    <div className="label">Business Addresses:</div>
                    <div className="value">{numeral(props.idModal.business_addresses).format('0,0')}</div>
                </div>
                <div className="info-item">
                    <div className="label">Total Addresses:</div>
                    <div className="value">{numeral(props.idModal.total_addresses).format('0,0')}</div>
                </div>
                <div className="info-item">
                    <div className="label">Printing Cost:</div>
                    <div className="value">{numeral(props.idModal.printing_cost).format('$0,0.00')}</div>
                </div>
                <div className="info-item">
                    <div className="label">Tax Cost:</div>
                    <div className="value">{numeral(props.idModal.tax_cost).format('$0,0.00')}</div>
                </div>
                <div className="info-item">
                    <div className="label">Total Cost:</div>
                    <div className="value">{numeral(props.idModal.total_cost).format('$0,0.00')}</div>
                </div>
            </div>
        </Dialog >
    );
});

export default RouteDetailsModal;
