import axios from 'axios';
import * as types from './ActivateAccount.types';
// import * as selectors from './ActivateAccount.selectors';
import * as loginActions from './../AdminLogin/AdminLogin.actions';
import { showNotification } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const activate = (id, token) => (dispatch, getState) => {
    dispatch({ type: types.ACTIVATE, payload: null });
    axios.post(apiUrl + '/abs-order/activate-account', { id, token },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.ACTIVATE_SUCCESS, payload: response.data });
            showNotification('Activation Successfully', 'Your account has been activated successfully', 'success');
        })
        .catch((error) => {
            dispatch({ type: types.ACTIVATE_ERROR, payload: null });
            if (error.response && error.response.status === 401) {
                showNotification('Unauthorized', 'You are not authorized to see this page', 'warning');
            } else if (error.response && error.response.status === 409) {
                showNotification('Invalid data', 'The Email or Username already exist', 'info');
            } else if (error.response && error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}