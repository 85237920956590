import React from 'react';
// import { Link } from 'react-router-dom';

const Testimonials = (props) => {
    return (
        <div className="testimonials-container content-container">
            <div className="title">Why Our Customers Love Us</div>
            <div className="main-title">
                Turning <span className="regular">dreams</span> into <span className="regular">reality</span>:
                Customer journeys with <span className="bold">Custom Brand It</span>!
            </div>
            <div className="testimonials">
                <div className="testimonial">
                    <div className="image">
                        <img src="https://ac-absolute-color.s3.amazonaws.com/css/testimonial-test.jpg" alt="Testimonial" />
                    </div>
                    <div className="name">Diego Salazar</div>
                    <div className="description">
                        I was so impressed with the quality of my custom products from Custom Brand It. The process was so easy
                        and the final result was better than I could have imagined. I will definitely be using their services again!
                    </div>
                    <div className="stars">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                    </div>
                </div>
                <div className="testimonial">
                    <div className="image">
                        <img src="https://ac-absolute-color.s3.amazonaws.com/css/testimonial-test.jpg" alt="Testimonial" />
                    </div>
                    <div className="name">Alonso Perez</div>
                    <div className="description">
                        I was looking for a unique and special gift for my sister's wedding and found Custom Brand It. I was able 
                        to create a custom tote bag with all of her favorite quotes and memories. The quality of the bag and printing
                        was fantastic, and my sister was overjoyed with the gift. I highly recommend Custom Brand It for anyone looking 
                        for a special and personal touch.
                    </div>
                    <div className="stars">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                    </div>
                </div>
                <div className="testimonial">
                    <div className="image">
                        <img src="https://ac-absolute-color.s3.amazonaws.com/css/testimonial-test.jpg" alt="Testimonial" />
                    </div>
                    <div className="name">Hugh Nguyen</div>
                    <div className="description">
                        I ordered a custom mug for my best friend's birthday and it turned out perfect! The online design tool was 
                        user-friendly and allowed me to get creative. My friend loved the mug and I received many compliments on it. 
                        Thanks Custom Brand It!
                    </div>
                    <div className="stars">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
