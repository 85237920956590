import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './AdminLayout.selectors';
import * as actions from './AdminLayout.actions';
import * as loginActions from './../AdminLogin/AdminLogin.actions';
import * as loginSelectors from './../AdminLogin/AdminLogin.selectors';
import Favicon from 'react-favicon';
import Header from './Header';
import Sidebar from './Sidebar';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import './AdminLayout.scss';

const AdminLayout = (props) => {
    /* State to props */
    const showSidebarMenu = useSelector(selectors.getShowSidebarMenu);
    const isAuthenticated = useSelector(loginSelectors.getIsAuthenticated);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setShowSidebarMenu = useCallback(() => dispatch(actions.setShowSidebarMenu()), [dispatch]);
    const logout = useCallback((history) => dispatch(loginActions.adminLogout(history)), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (user == null) {
        logout(props.history);
        return null;
    }

    const logoutHandler = event => {
        event.preventDefault();
        logout(props.history);
    }

    return (
        <div className="admin-layout-container">
            <Favicon url="https://ac-cbi.s3.amazonaws.com/favicon.ico" />
            <Helmet>
                <title>Custom Brand It Admin</title>
            </Helmet>
            <div className="content-area">
                <Header
                    user={user}
                    isAuthenticated={isAuthenticated}
                    showSidebarMenu={showSidebarMenu}
                    history={props.history}
                    logoutHandler={logoutHandler}
                    setShowSidebarMenu={setShowSidebarMenu}
                />
                <div className="layout-content">
                    <Sidebar
                        user={user}
                        history={props.history}
                    />
                    <div className="content">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
