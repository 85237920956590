import React from 'react';
// import { Link } from 'react-router-dom';

const Features = (props) => {
    return (
        <div className="features-container">
            <div className="content-container">
                <div className="title">What sets Custom Brand It apart?</div>
                <div className="features">
                    <div className="feature">
                        <div className="icon">
                            <img src="https://ac-cbi.s3.amazonaws.com/css/feature1.png" alt="Custom Brand It" />
                        </div>
                        <div className="feature-description">
                            <div className="name">No order minimums</div>
                            <div className="description">
                                Say goodbye to minimum order restrictions and hello to complete creative freedom
                                with Custom Brand It. With no minimum orders, you can create custom products for
                                any occasion, big or small.
                            </div>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="icon">
                            <img src="https://ac-cbi.s3.amazonaws.com/css/feature3.png" alt="Custom Brand It" />
                        </div>
                        <div className="feature-description">
                            <div className="name">Intuitive design tools</div>
                            <div className="description">
                                Creating your custom products has never been easier, thanks to our intuitive design tools.
                                Our platform is user-friendly, so you can bring your ideas to life with ease.
                            </div>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="icon">
                            <img src="https://ac-cbi.s3.amazonaws.com/css/feature2.png" alt="Custom Brand It" />
                        </div>
                        <div className="feature-description">
                            <div className="name">High printing quality</div>
                            <div className="description">
                                And, with our commitment to high printing quality, your designs will come to life with vivid color and sharp detail.
                            </div>
                        </div>
                    </div>
                    <div className="feature">
                        <div className="icon">
                            <img src="https://ac-cbi.s3.amazonaws.com/css/feature4.png" alt="Custom Brand It" />
                        </div>
                        <div className="feature-description">
                            <div className="name">Fast shipping</div>
                            <div className="description">
                                And, once you've placed your order, you'll receive your products in no time with our fast shipping options.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="message">
                    At <strong><i>Custom Brand It</i></strong>, we make customization accessible, affordable, and effortless. 
                    So, why wait? Start creating your <strong>unique</strong> products today!
                </div>
            </div>
        </div>
    );
}

export default Features;
