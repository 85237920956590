import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Banner from './Banner';
import Features from './Features';
import Popular from './Popular';
import Sales from './Sales';
import StartNow from './StartNow';
import Story from './Story';
import Testimonials from './Testimonials';
import './Landing.scss';
// import { empty, showNotification, stringifyEqualityCheck } from './../../utils';
// import * as selectors from './Landing.selectors';
import * as actions from './Landing.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';

const Landing = (props) => {
    /* State to props */

    /* Dispatch to props */
    const dispatch = useDispatch();
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Custom Brand It</title>
            </Helmet>
            <div className="landing-container">
                <Banner />
                <Popular />
                <Story />
                <Testimonials />
                <Sales />
                <Features />
                <StartNow />
            </div>
        </Layout>
    );
};

export default Landing;
