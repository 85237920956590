import React from 'react';
import { Dialog } from './../../common/dialog';

const ArtworksModal = React.memo((props) => {
    const hideHandler = () => {
        props.setIdModal(null);
        props.hideArtworksModal();
    };

    let order = null;
    for (let i = 0; i < props.items.length; i++) {
        if (parseInt(props.idModal) === parseInt(props.items[i].id)) {
            order = props.items[i];
            break;
        }
    }

    if (order === null) {
        return null;
    }

    const renderArtworks = () => {
        if (order.artworks.length === 0) {
            return <div className="artwork">No Artworks</div>;
        }

        return order.artworks.map((artwork, index) => {
            return (
                <div className="artwork">
                    <strong>File {index + 1}:</strong> <a href={artwork.file_url} target="_blank" rel="noopener noreferrer">{artwork.filename}</a>
                </div>
            );
        })
    };

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
    ];
    return (
        <Dialog
            name="artworks-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="admin-orders-artworks-dialog"
        >
            <div className="admin-orders-artworks-modal-container">
                <h1>Artworks</h1>
                <h2>Order #{props.idModal}:</h2>
                {renderArtworks()}
            </div>
        </Dialog >
    );
});

export default ArtworksModal;
