import React from 'react';
import { Link } from 'react-router-dom';

const Header = (props) => {
    let sidebarMenuClass = 'sidebar-menu';
    let overlayClass = 'overlay';
    if (props.showSidebarMenu) {
        sidebarMenuClass = 'sidebar-menu show-menu';
        overlayClass = 'overlay show-menu';
    }

    const menuClass = ['header-container'];
    const path = props.history.location.pathname;

    if (path === '/') {
        menuClass.push('no-background');
    }

    const handleLogout = () => {
        props.logoutHandler();
        props.setShowSidebarMenu();
    };

    let signUpMenu = (
        <Link to="/sign-up">
            Sign Up
        </Link>
    );
    let loginOutMenu = (
        <Link to="/login">
            Log In
        </Link>
    );
    let signUpMobileMenu = <li><Link to="/sign-up" onClick={props.setShowSidebarMenu}>Sign Up</Link></li>;
    let loginOutMobileMenu = <li><Link to="/login" onClick={props.setShowSidebarMenu}>Log In</Link></li>;
    if (props.user != null) {
        signUpMenu = (
            <Link to="/my-orders">
                Account
            </Link>
        );
        loginOutMenu = (
            <Link to="#" onClick={props.logoutHandler}>
                Log Out
            </Link>
        );

        signUpMobileMenu = <li><Link to="/my-orders" onClick={props.setShowSidebarMenu}>ACCOUNT</Link></li>;
        loginOutMobileMenu = <li><Link to="#" onClick={handleLogout}>LOG OUT</Link></li>;
    }

    return (
        <div className={menuClass.join(' ')}>
            <div className={sidebarMenuClass}>
                <div className="sidebar-content">
                    <div className="close-icon">
                        <i className="fa-solid fa-xmark" onClick={props.setShowSidebarMenu}></i>
                    </div>
                    <div className="menu-items">
                        <ul>
                            <li><Link to="/" onClick={props.setShowSidebarMenu}>Home</Link></li>
                            <li><Link to="/" onClick={props.setShowSidebarMenu}>Products</Link></li>
                            <li><Link to="/" onClick={props.setShowSidebarMenu}>How it Works</Link></li>
                            <li><Link to="/" onClick={props.setShowSidebarMenu}>Need Help?</Link></li>
                            {signUpMobileMenu}
                            {loginOutMobileMenu}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={overlayClass}></div>
            <div className="menu content-container">
                <div className="logo-container">
                    <Link to="/"><img src="https://ac-cbi.s3.amazonaws.com/logo.png" alt="Custom Brand It" /></Link>
                </div>
                <div className="menu-items">
                    <Link to="/" className="menu-item">
                        Products
                    </Link>
                    <Link to="/" className="menu-item">
                        How it Works
                    </Link>
                    <Link to="/" className="menu-item">
                        Need Help?
                    </Link>
                </div>
                <div className="sign-container">
                    <div className="login">
                        {loginOutMenu}
                    </div>
                    <div className="register">
                        {signUpMenu}
                    </div>
                </div>
            </div>
            <div className="mobile-menu">
                <div className="menu-icon" onClick={props.setShowSidebarMenu}>
                    <i className="fa-solid fa-bars"></i>
                </div>
                <div className="logo" onClick={props.logoutHandler}>
                    <Link to="/"><img src="https://ac-cbi.s3.amazonaws.com/logo.png" alt="Custom Brand It" /></Link>
                </div>
                <div className="sign-container">
                    <div className="login">
                        {loginOutMenu}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
