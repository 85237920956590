import React from 'react';
import { Link } from 'react-router-dom';

const Menu = (props) => {
    return (
        <div className="menu-container">
            <Link to="/my-information">
                <div className="menu-item active">
                    <div className="icon"><i className="fa-solid fa-user" /></div>
                    <div className="label">Your account</div>
                </div>
            </Link>
            <Link to="/my-address-book">
                <div className="menu-item">
                    <div className="icon"><i className="fa-solid fa-location-dot" /></div>
                    <div className="label">Address book</div>
                </div>
            </Link>
            <Link to="/my-orders">
                <div className="menu-item">
                    <div className="icon"><i className="fa-solid fa-cart-shopping" /></div>
                    <div className="label">Orders</div>
                </div>
            </Link>
            <Link to="/my-pending-orders">
                <div className="menu-item">
                    <div className="icon"><i className="fa-solid fa-clock" /></div>
                    <div className="label">Pending orders</div>
                </div>
            </Link>
            <Link to="/my-campaigns">
                <div className="menu-item">
                    <div className="icon"><i className="fa-solid fa-paper-plane" /></div>
                    <div className="label">Campaigns</div>
                </div>
            </Link>
        </div>
    );
}

export default Menu;
