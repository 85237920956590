import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Information from './Information';
import * as actions from './Privacy.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import './Privacy.scss';

const Privacy = (props) => {
    /* Dispatch to props */
    const dispatch = useDispatch();
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);

        return () => {
            resetState();
        };
    }, [setHistory, resetState, props.history]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Privacy - Custom Brand It</title>
            </Helmet>
            <div className="privacy-container">
                <Information />
            </div>
        </Layout>
    );
};

export default Privacy;
