import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Details from './Details';
import Menu from './Menu';
import RouteDetailsModal from './RouteDetailsModal';
import { stringifyEqualityCheck } from './../../utils';
import * as selectors from './MyOrderDetails.selectors';
import * as actions from './MyOrderDetails.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import * as dialog from './../common/dialog';
import './MyOrderDetails.scss';

const MyOrderDetails = (props) => {
    /* State to props */
    const order = useSelector(selectors.getOrder, stringifyEqualityCheck);
    const fetchingOrder = useSelector(selectors.getFetchingOrder);
    const idModal = useSelector(selectors.getIdModal);
    const typeModal = useSelector(selectors.getTypeModal);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchOrder = useCallback((id) => dispatch(actions.fetchOrder(id)), [dispatch]);
    const setIdModal = useCallback((id) => dispatch(actions.setIdModal(id)), [dispatch]);
    const setTypeModal = useCallback((type) => dispatch(actions.setTypeModal(type)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const showRouteDetailsModal = useCallback(() => dispatch(dialog.actions.showDialog('route-details-modal')), [dispatch]);
    const hideRouteDetailsModal = useCallback(() => dispatch(dialog.actions.hideDialog('route-details-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchOrder(props.match.params.id);

        return () => {
            resetState();
        };
    }, [fetchOrder, setHistory, resetState, props.history, props.match.params.id]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>My Order Details - Custom Brand It</title>
            </Helmet>
            <div className="my-order-details-container">
                <div className="content-container">
                    <Menu
                        history={props.history}
                    />
                    <Details
                        order={order}
                        fetchingOrder={fetchingOrder}
                        setIdModal={setIdModal}
                        setTypeModal={setTypeModal}
                        showRouteDetailsModal={showRouteDetailsModal}
                    />
                </div>
            </div>
            <RouteDetailsModal
                idModal={idModal}
                typeModal={typeModal}
                hideRouteDetailsModal={hideRouteDetailsModal}
                setIdModal={setIdModal}
                setTypeModal={setTypeModal}
            />
        </Layout>
    );
};

export default MyOrderDetails;
