import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './Layout.selectors';
import * as actions from './Layout.actions';
import * as loginActions from './../Login/Login.actions';
import * as loginSelectors from './../Login/Login.selectors';
import Favicon from 'react-favicon';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { showNotification, stringifyEqualityCheck } from './../../utils';
import './Layout.scss';

const Layout = (props) => {
    /* State to props */
    const showSidebarMenu = useSelector(selectors.getShowSidebarMenu);
    const cartTotalItems = useSelector(selectors.getCartTotalItems);
    const fetchingCartTotalItems = useSelector(selectors.getFetchingCartTotalItems);
    const helpDeskItems = useSelector(selectors.getHelpDeskItems);
    const isAuthenticated = useSelector(loginSelectors.getIsAuthenticated);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const setShowSidebarMenu = useCallback(() => dispatch(actions.setShowSidebarMenu()), [dispatch]);
    const fetchCartTotalItems = useCallback(() => dispatch(actions.fetchCartTotalItems()), [dispatch]);
    const checkHelpDesk = useCallback(() => dispatch(actions.checkHelpDesk()), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const logout = useCallback((history) => dispatch(loginActions.logout(history)), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);

        const path = props.history.location.pathname;
        if (
            user == null && (['/account', '/my-address-book', '/my-campaigns', '/my-information', '/my-pending-orders'].includes(path) ||
            path.includes('/campaign/') || path.includes('/checkout/') || path.includes('/choose-product/') || path.includes('/map/project/') ||
            path.includes('/my-orders'))
        ) {
            showNotification('Login required', 'You need to login to see this section', 'warning');
            props.history.replace('/')
        }

        return () => {
            resetState();
        };
    }, [fetchCartTotalItems, resetState, checkHelpDesk, user, props.history]);

    const logoutHandler = event => {
        event.preventDefault();
        logout(props.history);
    }

    return (
        <div className="layout-container">
            <Favicon url="https://ac-cbi.s3.amazonaws.com/favicon.ico" />
            <Helmet>
                <title>Custom Brand It</title>
            </Helmet>
            <div className="content-area">
                <Header
                    user={user}
                    isAuthenticated={isAuthenticated}
                    showSidebarMenu={showSidebarMenu}
                    cartTotalItems={cartTotalItems}
                    fetchingCartTotalItems={fetchingCartTotalItems}
                    helpDeskItems={helpDeskItems}
                    history={props.history}
                    logoutHandler={logoutHandler}
                    setShowSidebarMenu={setShowSidebarMenu}
                />
                <div className="layout-content">
                    {props.children}
                </div>
                <Footer
                    logoutHandler={logoutHandler}
                />
            </div>
        </div>
    );
};

export default Layout;
