import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Editor from './Editor';
import './Designer.scss';
// import { empty, showNotification, stringifyEqualityCheck } from './../../utils';
// import * as selectors from './Designer.selectors';
import * as actions from './Designer.actions';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';

const Designer = (props) => {
    /* State to props */

    /* Dispatch to props */
    const dispatch = useDispatch();
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <Fragment>
            <Favicon url="https://ac-cbi.s3.amazonaws.com/favicon.ico" />
            <Helmet>
                <title>Custom Brand It</title>
            </Helmet>
            <div className="designer-container">
                <Editor />
            </div>
        </Fragment>
    );
};

export default Designer;
