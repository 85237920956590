import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './SignUp.actions';
import * as selectors from './SignUp.selectors';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import './SignUp.scss';

const SignUp = (props) => {
    /* State to props */
    const registering = useSelector(selectors.getRegistering);
    const form = useSelector(selectors.getForm, stringifyEqualityCheck);    

    /* Dispatch to props */
    const dispatch = useDispatch();
    const register = useCallback(() => dispatch(actions.register()), [dispatch]);
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');
        
        setHistory(props.history);

        return () => {
            resetState();
        };
    }, [setHistory, resetState, props.history]);

    return (
        <div className="sign-up-container">
            <Helmet>
                <title>Sign Up - Custom Brand It</title>
            </Helmet>
            <Favicon url="https://ac-cbi.s3.amazonaws.com/favicon.ico" />
            <Form
                form={form}
                registering={registering}
                history={props.history}
                location={props.location}
                register={register}
                setField={setField}
            />
        </div>
    );
};

export default SignUp;
