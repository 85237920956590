import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Information from './Information';
import Menu from './Menu';
import { stringifyEqualityCheck } from './../../utils';
import * as selectors from './MyInformation.selectors';
import * as actions from './MyInformation.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import './MyInformation.scss';

const MyInformation = (props) => {
    /* State to props */
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const savingInformation = useSelector(selectors.getSavingInformation);
    const savingPassword = useSelector(selectors.getSavingPassword);
    const states = useSelector(selectors.getStates, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const saveInformation = useCallback(() => dispatch(actions.saveInformation()), [dispatch]);
    const savePassword = useCallback(() => dispatch(actions.savePassword()), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchInformation();

        return () => {
            resetState();
        };
    }, [fetchInformation, setHistory, resetState, props.history]);

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>My Information - Custom Brand It</title>
            </Helmet>
            <div className="my-information-container">
                <div className="content-container">
                    <Menu
                        history={props.history}
                    />
                    <Information
                        information={information}
                        fetchingInformation={fetchingInformation}
                        states={states}
                        savingInformation={savingInformation}
                        savingPassword={savingPassword}
                        setField={setField}
                        saveInformation={saveInformation}
                        savePassword={savePassword}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default MyInformation;
