import React from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { dateToTimezone } from './../../../utils';

const Details = (props) => {
    if (props.actionType === 'list') {
        return null;
    }

    if (props.fetchingElement) {
        return (
            <div className="form-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleZipDetails = (zipCode) => {
        props.setIdModal(zipCode);
        props.setTypeModal('zip-code');
        props.showRouteDetailsModal();
    };

    const handleRouteDetails = (route) => {
        props.setIdModal(route);
        props.setTypeModal('route');
        props.showRouteDetailsModal();
    };

    const renderProductOptions = () => {
        return props.item.productOptions.map((option) => {
            return (
                <div className="item" >
                    <div className="label">{option.feature_name}:</div>
                    <div className="value">{option.feature_option_name}</div>
                </div >
            );
        });
    };

    const renderDropsOptionDrops = () => {
        if (props.item.drops_option_drops != null) {
            return (
                <div className="item">
                    <div className="label">Drops:</div>
                    <div className="value">{props.item.drops_option_drops}</div>
                </div>
            );
        }

        return null;
    };

    const renderDrops = () => {
        return props.item.campaignDrops.map((drop, index) => {
            return (
                <div className="item">
                    <div className="label">Drop #{index + 1}:</div>
                    <div className="value">
                        <div>
                            {numeral(drop.total_addresses).format('0,0')} pieces should reach the target audience the week of&nbsp;
                            {dateToTimezone(drop.start_date, 'MM/DD/YYYY')} to {dateToTimezone(drop.end_date, 'MM/DD/YYYY')}
                        </div>
                        <div className="mt-2">Routes in this drop: {drop.orderRoutesText}</div>
                    </div>
                </div>
            );
        });
    };

    const renderZipCodes = () => {
        return props.item.zipCodes.map((zipCode) => {
            return (
                <div className="sub-item">
                    <div className="label">{zipCode.zip_code}</div>
                    <div className="view-details" onClick={handleZipDetails.bind(this, zipCode)}>
                        <i className="fa-solid fa-table-list"></i> View Details
                    </div>
                </div>
            );
        });
    };

    const renderRoutes = () => {
        return props.item.routes.map((route) => {
            return (
                <div className="sub-item">
                    <div className="label">{route.zip_code}-{route.crid_id}</div>
                    <div className="view-details" onClick={handleRouteDetails.bind(this, route)}>
                        <i className="fa-solid fa-table-list"></i> View Details
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="details-container">
            <div className="order-summary-container summary-container">
                <div className="title">Order Summary</div>
                <div className="item">
                    <div className="label">Order ID:</div>
                    <div className="value">{props.item.id}</div>
                </div>
                <div className="item">
                    <div className="label">Campaign Name:</div>
                    <div className="value">{props.item.name}</div>
                </div>
                <div className="item">
                    <div className="label">Order Status:</div>
                    <div className="value">{props.item.status_name}</div>
                </div>
                <div className="item">
                    <div className="label">Customer ID:</div>
                    <div className="value">{props.item.customer_id}</div>
                </div>
                <div className="item">
                    <div className="label">Customer Name:</div>
                    <div className="value">{props.item.customer_name}</div>
                </div>
                <div className="item">
                    <div className="label">Customer Email:</div>
                    <div className="value">{props.item.customer_email}</div>
                </div>
                <div className="item">
                    <div className="label">Product:</div>
                    <div className="value">{props.item.product_name}</div>
                </div>
                <div className="item">
                    <div className="label">ZIP Codes Searched:</div>
                    <div className="value">{numeral(props.item.total_zip_codes_searched).format('0,0')}</div>
                </div>
                <div className="item">
                    <div className="label">Routes Selected:</div>
                    <div className="value">{numeral(props.item.total_order_routes).format('0,0')}</div>
                </div>
                <div className="item">
                    <div className="label">Total Addresses:</div>
                    <div className="value">{numeral(props.item.calculated_total_addresses).format('0,0')}</div>
                </div>
                <div className="item">
                    <div className="label">Total Cost:</div>
                    <div className="value">{numeral(props.item.calculated_total_cost).format('$0,0.00')}</div>
                </div>
                {/* <div className="item">
                    <div className="label">Payment Type:</div>
                    <div className="value">{props.item.is_credit_card_payment ? 'Credit Card' : 'Paypal'}</div>
                </div> */}
                <div className="item">
                    <div className="label">Created at:</div>
                    <div className="value">{dateToTimezone(props.item.order_datetime, 'MM/DD/YYYY')}</div>
                </div>
            </div>
            <div className="cost-summary-container summary-container">
                <div className="title">Cost Summary</div>
                <div className="item">
                    <div className="label">Residential Addresses:</div>
                    <div className="value">{numeral(props.item.calculated_residential_addresses).format('0,0')}</div>
                </div>
                <div className="item">
                    <div className="label">Business Addresses:</div>
                    <div className="value">{numeral(props.item.calculated_business_addresses).format('0,0')}</div>
                </div>
                <div className="item">
                    <div className="label">Total Addresses:</div>
                    <div className="value">{numeral(props.item.calculated_total_addresses).format('0,0')}</div>
                </div>
                <div className="item">
                    <div className="label">Shipping Cost:</div>
                    <div className="value">{numeral(props.item.calculated_shipping_cost).format('$0,0.00')}</div>
                </div>
                <div className="item">
                    <div className="label">Printing Cost:</div>
                    <div className="value">
                        <div>{numeral(props.item.calculated_printing_cost).format('$0,0.00')}</div>
                        <div class="unit-cost">({numeral(props.item.calculated_printing_cost / props.item.calculated_total_addresses).format('$0,0.00')}/Piece)</div>
                    </div>
                </div>
                <div className="item">
                    <div className="label">Mailing Frequency:</div>
                    <div className="value">{props.item.mailing_frequency}</div>
                </div>
                <div className="item">
                    <div className="label">Frequency Setup:</div>
                    <div className="value">{numeral(props.item.printing_fee).format('$0,0.00')}</div>
                </div>
                <div className="item">
                    <div className="label">Sample Flat Rate:</div>
                    <div className="value">{numeral(props.item.sample_flat_rate).format('$0,0.00')}</div>
                </div>
                <div className="item">
                    <div className="label">Discount:</div>
                    <div className="value">-{numeral(props.item.total_cost_discounted).format('$0,0.00')}</div>
                </div>
                <div className="item">
                    <div className="label">Tax Cost:</div>
                    <div className="value">{numeral(props.item.tax_cost).format('$0,0.00')}</div>
                </div>
                <div className="item">
                    <div className="label">Total Cost:</div>
                    <div className="value">{numeral(props.item.calculated_total_cost).format('$0,0.00')}</div>
                </div>
            </div>
            <div className="campaign-information summary-container">
                <div className="title">Campaign Information</div>
                <div className="item">
                    <div className="label">Mail Times:</div>
                    <div className="value">{props.item.mail_times_option_mail_times}</div>
                </div>
                {renderDropsOptionDrops()}
                <div className="item">
                    <div className="label">Frequency:</div>
                    <div className="value">Every {props.item.mail_frequency_option_weeks} Week(s)</div>
                </div>
                <div className="item">
                    <div className="label">Send All Pieces at Once?:</div>
                    <div className="value">{props.item.mail_all_pieces ? 'Yes' : 'No'}</div>
                </div>
                {renderDrops()}
            </div>
            <div className="product-information summary-container">
                <div className="title">Product Information</div>
                <div className="item">
                    <div className="label">Product:</div>
                    <div className="value">{props.item.product_name}</div>
                </div>
                {renderProductOptions()}
            </div>
            <div className="zip-codes-container summary-container">
                <div className="title">ZIP Codes List</div>
                <div className="detailed-item">
                    <div className="label">ZIP Codes Searched: {numeral(props.item.zipCodes.length).format('0,0')}</div>
                    <div className="value">
                        {renderZipCodes()}
                    </div>
                </div>
            </div>
            <div className="routes-container summary-container">
                <div className="title">Routes List</div>
                <div className="detailed-item">
                    <div className="label">Routes Selected: {numeral(props.item.routes.length).format('0,0')}</div>
                    <div className="value">
                        {renderRoutes()}
                    </div>
                </div>
            </div>
            <div className="billing-information summary-container">
                <div className="title">Billing Information</div>
                <div className="item">
                    <div className="label">Name:</div>
                    <div className="value">{props.item.billingAddress.name} {props.item.billingAddress.last_name}</div>
                </div>
                <div className="item">
                    <div className="label">Company:</div>
                    <div className="value">{props.item.billingAddress.company}</div>
                </div>
                <div className="item">
                    <div className="label">Website:</div>
                    <div className="value">{props.item.billingAddress.website}</div>
                </div>
                <div className="item">
                    <div className="label">Is Reseller?:</div>
                    <div className="value">{props.item.billingAddress.is_reseller ? 'Yes' : 'No'}</div>
                </div>
                <div className="item">
                    <div className="label">Tax ID:</div>
                    <div className="value">{props.item.billingAddress.tax_id}</div>
                </div>
                <div className="item">
                    <div className="label">Industry:</div>
                    <div className="value">{props.item.billingAddress.industry}</div>
                </div>
                <div className="item">
                    <div className="label">Role:</div>
                    <div className="value">{props.item.billingAddress.role}</div>
                </div>
                <div className="item">
                    <div className="label">ZIP Code:</div>
                    <div className="value">{props.item.billingAddress.zip_code}</div>
                </div>
                <div className="item">
                    <div className="label">Phone:</div>
                    <div className="value">{props.item.billingAddress.phone}</div>
                </div>
                <div className="item">
                    <div className="label">Cellphone:</div>
                    <div className="value">{props.item.billingAddress.cellphone}</div>
                </div>
                <div className="item">
                    <div className="label">Fax:</div>
                    <div className="value">{props.item.billingAddress.fax}</div>
                </div>
                <div className="item">
                    <div className="label">Address:</div>
                    <div className="value">{props.item.billingAddress.address1} {props.item.billingAddress.address2}</div>
                </div>
                <div className="item">
                    <div className="label">Country:</div>
                    <div className="value">{props.item.billingAddress.country}</div>
                </div>
                <div className="item">
                    <div className="label">State:</div>
                    <div className="value">{props.item.billingAddress.state}</div>
                </div>
                <div className="item">
                    <div className="label">City:</div>
                    <div className="value">{props.item.billingAddress.city}</div>
                </div>
            </div>
            <div className="buttons-container">
                <div className="button-container">
                    <Link to="/admin/orders">
                        <button className="cancel-button">Go Back</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Details;
