import React from 'react';

const Form = (props) => {
    const setFieldHandler = event => {
        props.setField(event.target.name, event.target.value);
    }

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            props.resetPassword();
        }
    }

    const handleResetPassword = () => {
        props.resetPassword();
    }

    const handleLogin = () => {
        if (parseInt(props.userType) === 1) {   // Client
            props.history.push('/login');
        } else {
            props.history.push('/admin/login');
        }
    }

    let container = (
        <div className="register-container">
            <h1>Reset your Password</h1>
            <div className="fields">
                <div className="field">
                    <div className="icon"><i className="fa-solid fa-key"></i></div>
                    <input type="password" name="password" value={props.form.password} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Password" />
                </div>
                <div className="field">
                    <div className="icon"><i className="fa-solid fa-key"></i></div>
                    <input type="password" name="confirm_password" value={props.form.confirm_password} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Confirm Password" />
                </div>
            </div>
            <button type="button" onClick={handleResetPassword} disabled={props.resetting}>{props.resetting ? <i className="fas fa-circle-notch fa-spin"></i> : 'Reset Password'}</button>
        </div>
    )
    if (props.reset) {
        container = (
            <div className="reset-container">
                <p>Your password has been reset successfully. Please, click on the button below to sign in.</p>
                <button onClick={handleLogin}>Sign In</button>
            </div>
        );
    }

    return (
        <div className="form-container">
            <div className="logo-container">
                <img src="https://ac-cbi.s3.amazonaws.com/logo.jpg" alt="Custom Brand It" />
            </div>
            {container}
        </div>
    );
}

export default Form;
