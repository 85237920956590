import { combineReducers } from 'redux';
import * as dialog from './components/common/dialog';
import AccountReducer from './components/Account/Account.reducer';
import ActivateAccountReducer from './components/ActivateAccount/ActivateAccount.reducer';
import AdminLayoutReducer from './components/AdminLayout/AdminLayout.reducer';
import AdminConfigurationReducer from './components/AdminConfiguration/AdminConfiguration.reducer';
import AdminCustomersReducer from './components/AdminCustomers/AdminCustomers.reducer';
import AdminLoginReducer from './components/AdminLogin/AdminLogin.reducer';
import AdminOrdersReducer from './components/AdminOrders/AdminOrders.reducer';
import AdminUsersReducer from './components/AdminUsers/AdminUsers.reducer';
import DesignerReducer from './components/Designer/Designer.reducer';
import ForgotPasswordReducer from './components/ForgotPassword/ForgotPassword.reducer';
import LandingReducer from './components/Landing/Landing.reducer';
import LayoutReducer from './components/Layout/Layout.reducer';
import LoginReducer from './components/Login/Login.reducer';
import MyInformationReducer from './components/MyInformation/MyInformation.reducer';
import MyOrderDetailsReducer from './components/MyOrderDetails/MyOrderDetails.reducer';
import MyOrdersReducer from './components/MyOrders/MyOrders.reducer';
import PrivacyReducer from './components/Privacy/Privacy.reducer';
import ResetPasswordReducer from './components/ResetPassword/ResetPassword.reducer';
import SignUpReducer from './components/SignUp/SignUp.reducer';
import TermsReducer from './components/Terms/Terms.reducer';

const reducer = combineReducers({
    account: AccountReducer,
    activateAccount: ActivateAccountReducer,
    adminLayout: AdminLayoutReducer,
    adminConfiguration: AdminConfigurationReducer,
    adminCustomers: AdminCustomersReducer,
    adminLogin: AdminLoginReducer,
    adminOrders: AdminOrdersReducer,
    adminUsers: AdminUsersReducer,
    designer: DesignerReducer,
    forgotPassword: ForgotPasswordReducer,
    dialog: dialog.reducer,
    landing: LandingReducer,
    layout: LayoutReducer,
    login: LoginReducer,
    myInformation: MyInformationReducer,
    myOrderDetails: MyOrderDetailsReducer,
    myOrders: MyOrdersReducer,
    privacy: PrivacyReducer,
    resetPassword: ResetPasswordReducer,
    signUp: SignUpReducer,
    terms: TermsReducer,
});

export default reducer;
