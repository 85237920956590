import React from 'react';
import { Dialog } from './../../common/dialog';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const GeneratePdfModal = React.memo((props) => {
    const hideHandler = () => {
        props.setIdModal(null);
        props.hideGeneratePdfModal();
    };

    const handleSetPdfDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setPdfDate(value);
    };

    let buttonLabel = 'Generate PDF';
    if (props.generatingPdf) {
        buttonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    let actions = [
        <button key="cancel-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideHandler}>Cancel</button>,
        <button key="generate-button" className="btn btn-light btn-sm admin-button" onClick={props.generatePdf.bind(this, props.idModal)}>{buttonLabel}</button>,
    ];
    return (
        <Dialog
            name="generate-pdf-modal"
            modal={false}
            actions={actions}
            contentStyle={{ width: 800 }}
            bodyStyle={{ overflow: 'initial' }}
            className="admin-orders-generate-pdf-dialog"
        >
            <div className="admin-orders-generate-pdf-modal-container">
                <h1>Generate Facing Slips</h1>
                <p>Enter the information below and click on the Generate PDF button to create the PDF for the routes of the Order <strong>#{props.idModal}</strong>.</p>
                <div className="field">
                    <div className="label">Date <span className="required">*</span>:</div>
                    <DatePicker
                        selected={props.pdfDate == null ? null : new Date(props.pdfDate + ' 12:00:00')}
                        onChange={handleSetPdfDate}
                    />
                </div>
                <div className="field">
                    <div className="label">Permit Holder Name <span className="required">*</span>:</div>
                    <input type="text" value={props.pdfPermitHolderName} onChange={(event) => props.setPdfPermitHolderName(event.target.value)} />
                </div>
                <div className="field">
                    <div className="label">Permit Number <span className="required">*</span>:</div>
                    <input type="text" value={props.pdfPermitNumber} onChange={(event) => props.setPdfPermitNumber(event.target.value)} />
                </div>
            </div>
        </Dialog >
    );
});

export default GeneratePdfModal;
