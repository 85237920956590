import Immutable from 'immutable';
import * as types from './AdminOrders.types';

const initialState = {
    actionType: 'list',
    items: [],
    item: {},
    fetchingElements: false,
    fetchingElement: false,
    saving: false,
    deleting: false,
    idModal: null,
    typeModal: null,
    pagination: {
        totalElements: 0,
        activePage: 1,
        totalPages: 0,
        elementsPerPage: 15,
    },
    sort: {
        column: 'id',
        type: 'desc',
    },
    search: '',
    searchType: 'id',
    exportingExcel: false,
    fetchingInformation: false,
    information: {
        statuses: [],
    },
    pendingSaveIds: [],
    savingId: null,
    pdfDate: null,
    pdfPermitHolderName: '',
    pdfPermitNumber: '',
    generatingPdf: false,
    creatingScTicket: false,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ELEMENTS:
            return state.setIn(['fetchingElements'], true).toJS();

        case types.FETCH_ELEMENTS_SUCCESS:
            return state.setIn(['fetchingElements'], false)
                .setIn(['items'], action.payload.elements)
                .setIn(['pagination', 'totalElements'], action.payload.totalElements)
                .setIn(['pagination', 'activePage'], action.payload.activePage)
                .setIn(['pagination', 'totalPages'], action.payload.totalPages)
                .setIn(['pagination', 'elementsPerPage'], action.payload.elementsPerPage)
                .toJS();

        case types.FETCH_ELEMENTS_ERROR:
            return state.setIn(['fetchingElements'], false).toJS();

        case types.FETCH_INFORMATION:
            return state.setIn(['fetchingInformation'], true).toJS();

        case types.FETCH_INFORMATION_SUCCESS:
            return state.setIn(['fetchingInformation'], false)
                .setIn(['information', 'statuses'], action.payload.statuses)
                .toJS();

        case types.FETCH_INFORMATION_ERROR:
            return state.setIn(['fetchingInformation'], false).toJS();

        case types.FETCH_ELEMENT:
            return state.setIn(['fetchingElement'], true).toJS();

        case types.FETCH_ELEMENT_SUCCESS:
            return state.setIn(['fetchingElement'], false)
                .setIn(['item'], action.payload)
                .toJS();

        case types.FETCH_ELEMENT_ERROR:
            return state.setIn(['fetchingElement'], false).toJS();

        case types.SAVE_ELEMENT:
            return state.setIn(['saving'], true).toJS();

        case types.SAVE_ELEMENT_SUCCESS:
            return state.setIn(['saving'], false)
                .setIn(['item'], {})
                .toJS();

        case types.SAVE_ELEMENT_ERROR:
            return state.setIn(['saving'], false).toJS();

        case types.SAVE_STATUS:
            return state.setIn(['saving'], true)
                .setIn(['savingId'], action.payload)
                .toJS();

        case types.SAVE_STATUS_SUCCESS:
            return state.setIn(['saving'], false)
                .setIn(['savingId'], null)
                .toJS();

        case types.SAVE_STATUS_ERROR:
            return state.setIn(['saving'], false)
                .setIn(['savingId'], null)
                .toJS();

        case types.DELETE_ITEM:
            return state.setIn(['deleting'], true).toJS();

        case types.DELETE_ITEM_SUCCESS:
            return state.setIn(['deleting'], false)
                .setIn(['idModal'], null)
                .toJS();

        case types.DELETE_ITEM_ERROR:
            return state.setIn(['deleting'], false).toJS();

        case types.EXPORT_EXCEL:
            return state.setIn(['exportingExcel'], true).toJS();

        case types.EXPORT_EXCEL_SUCCESS:
            return state.setIn(['exportingExcel'], false).toJS();

        case types.EXPORT_EXCEL_ERROR:
            return state.setIn(['exportingExcel'], false).toJS();

        case types.GENERATE_PDF:
            return state.setIn(['generatingPdf'], true).toJS();

        case types.GENERATE_PDF_SUCCESS:
            return state.setIn(['generatingPdf'], false)
                .setIn(['pdfDate'], null)
                .setIn(['pdfPermitHolderName'], '')
                .setIn(['pdfPermitNumber'], '')
                .toJS();

        case types.GENERATE_PDF_ERROR:
            return state.setIn(['generatingPdf'], false).toJS();

        case types.CREATE_SC_TICKET:
            return state.setIn(['creatingScTicket'], true).toJS();

        case types.CREATE_SC_TICKET_SUCCESS:
            return state.setIn(['creatingScTicket'], false).toJS();

        case types.CREATE_SC_TICKET_ERROR:
            return state.setIn(['creatingScTicket'], false).toJS();

        case types.SET_ACTION_TYPE:
            return state.setIn(['actionType'], action.payload).toJS();

        case types.SET_ACTIVE_PAGE:
            return state.setIn(['pagination', 'activePage'], action.payload).toJS();

        case types.SET_SORT:
            return state.setIn(['sort', 'type'], action.payload.type)
                .setIn(['sort', 'column'], action.payload.column)
                .toJS();

        case types.SET_SEARCH:
            return state.setIn(['search'], action.payload).toJS();

        case types.SET_SEARCH_TYPE:
            return state.setIn(['searchType'], action.payload).toJS();

        case types.SET_FIELD:
            return state.setIn(['item', action.payload.field], action.payload.value).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.SET_TYPE_MODAL:
            return state.setIn(['typeModal'], action.payload).toJS();

        case types.ADD_PENDING_SAVE_ID:
            return state.updateIn(['pendingSaveIds'], (pendingSaveIds) => {
                const newPendingSaveIds = pendingSaveIds.toJS();
                const index = newPendingSaveIds.indexOf(parseInt(action.payload));

                if (index === -1) {
                    newPendingSaveIds.push(parseInt(action.payload));
                }

                return newPendingSaveIds;
            }).toJS();

        case types.REMOVE_PENDING_SAVE_ID:
            return state.updateIn(['pendingSaveIds'], (pendingSaveIds) => {
                const newPendingSaveIds = pendingSaveIds.toJS();
                const index = newPendingSaveIds.indexOf(parseInt(action.payload));

                if (index !== -1) {
                    newPendingSaveIds.splice(index, 1);
                }

                return newPendingSaveIds;
            }).toJS();

        case types.CHANGE_STATUS:
            return state.updateIn(['items'], (items) => {
                const newItems = items.toJS();
                const index = newItems.findIndex((item) => parseInt(item.id) === parseInt(action.payload.id));

                if (index !== -1) {
                    newItems[index]['order_status_id'] = action.payload.value;
                }

                return newItems;
            }).toJS();

        case types.SET_PDF_DATE:
            return state.setIn(['pdfDate'], action.payload).toJS();

        case types.SET_PDF_PERMIT_HOLDER_NAME:
            return state.setIn(['pdfPermitHolderName'], action.payload).toJS();

        case types.SET_PDF_PERMIT_NUMBER:
            return state.setIn(['pdfPermitNumber'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}