import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './ResetPassword.actions';
import * as selectors from './ResetPassword.selectors';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import './ResetPassword.scss';

const ResetPassword = (props) => {
    /* State to props */
    const resetting = useSelector(selectors.getResetting);
    const reset = useSelector(selectors.getReset);
    const userType = useSelector(selectors.getUserType);
    const form = useSelector(selectors.getForm, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const resetPassword = useCallback((history) => dispatch(actions.resetPassword(history)), [dispatch]);
    const setId = useCallback((value) => dispatch(actions.setId(value)), [dispatch]);
    const setToken = useCallback((value) => dispatch(actions.setToken(value)), [dispatch]);
    const setField = useCallback((name, value) => dispatch(actions.setField(name, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');
        
        setId(props.match.params.id);
        setToken(props.match.params.token);

        return () => {
            resetState();
        };
    }, [setId, setToken, resetState, props.match.params.id, props.match.params.token]);

    return (
        <div className="reset-password-container">
            <Helmet>
                <title>Reset Password - Custom Brand It</title>
            </Helmet>
            <Favicon url="https://ac-cbi.s3.amazonaws.com/favicon.ico" />
            <Form
                form={form}
                resetting={resetting}
                reset={reset}
                userType={userType}
                history={props.history}
                setField={setField}
                resetPassword={resetPassword}
            />
        </div>
    );
};

export default ResetPassword;
