import React from 'react';
import { Link } from 'react-router-dom';

const Footer = React.memo((props) => {
    return (
        <div className="footer-container">
            <div className="footer content-container">
                <div className="main-menu">
                    <div className="column1">
                        <div className="title">Create with Custom Brand It</div>
                        <Link to="/">Product catalog</Link>
                        <Link to="/">How it works</Link>
                    </div>
                    <div className="column2">
                        <div className="title">About Custom Brand It</div>
                        <Link to="/">Our history</Link>
                        <Link to="/">Contact us</Link>
                    </div>
                    <div className="column3">
                        <div className="title">Policies</div>
                        <Link to="/">Returns</Link>
                        <Link to="/">Shippings</Link>
                        <Link to="/">Policies</Link>
                    </div>
                    <div className="column4">
                        <div className="title">Resources</div>
                        <Link to="/">FAQ</Link>
                        <Link to="/">Help Center</Link>
                    </div>
                </div>
                <div className="social-menu">
                    <div className="logo">
                        <Link to="/"><img src="https://ac-cbi.s3.amazonaws.com/logo.png" alt="Custom Brand It" /></Link>
                    </div>
                    <div className="social-media">
                        <div className="title">Follow Us</div>
                        <div className="icon">
                            <a href="/"><i className="fa-brands fa-facebook-f"></i></a>
                        </div>
                        <div className="icon">
                            <a href="/"><i className="fa-brands fa-instagram"></i></a>
                        </div>
                        <div className="icon">
                            <a href="/"><i className="fa-brands fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
                <div className="copy">
                    <div className="copyright">
                        Copyright © AbsoluteColor.com | Wholesale Online Print Services. All Rights Reserved.
                        <br />
                        11101 Ella Blvd. : Houston, Texas 77067 : (p)713-996-0202 : (p)800-386-3054 : (f)713-996-0203
                    </div>
                    <div className="payment-methods">
                        <div className="title">We accept</div>
                        <img src="https://ac-cbi.s3.amazonaws.com/css/payment-methods.png" alt="Payment Methods" />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Footer;
