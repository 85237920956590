import React from 'react';

const Form = (props) => {
    const setFieldHandler = event => {
        props.setField(event.target.name, event.target.value);
    }

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            props.login(props.history);
        }
    }

    const handleSignIn = () => {
        props.login(props.history);
    }

    const handleFrontendLogin = () => {
        props.history.push('/');
    };

    const forgotPasswordHandler = () => {
        props.history.push('/forgot-password');
    }

    return (
        <div className="admin-login-container">
            <div className="logo-container">
                <img src="https://ac-cbi.s3.amazonaws.com/logo.png" alt="Custom Brand It" />
            </div>
            <div className="sign-container">
                <div className="sign-in-container">
                    <h1>Admin Log in</h1>
                    <div className="fields">
                        <div className="field">
                            <div className="icon"><i className="fa-solid fa-user"></i></div>
                            <input type="text" name="email" value={props.form.email} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Email" />
                        </div>
                        <div className="field">
                            <div className="icon"><i className="fa-solid fa-key"></i></div>
                            <input type="password" name="password" value={props.form.password} onChange={setFieldHandler} onKeyPress={keyPressHandler} placeholder="Password" />
                        </div>
                    </div>
                    <div className="forgot-password-link-container">
                        <div className="forgot-password" onClick={forgotPasswordHandler}>Forgot your password?</div>
                    </div>
                    <div className="text-center">
                        <button type="button" onClick={handleSignIn} disabled={props.isLogging}>{props.isLogging ? <i className="fas fa-circle-notch fa-spin"></i> : 'Enter'}</button>
                    </div>
                </div>
                <div className="login-sign-up-container">
                    <h1>Admin Dashboard</h1>
                    <p>This is the log in page to enter to the Admin Dashboard. If you want to log in to the platform please click on the button below.</p>
                    <div className="text-center">
                        <button type="button" onClick={handleFrontendLogin}>Go to Frontend</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;
