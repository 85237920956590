import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import store from './store';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import './index.scss';
import Account from './components/Account';
import ActivateAccount from './components/ActivateAccount';
import AdminConfiguration from './components/AdminConfiguration';
import AdminCustomers from './components/AdminCustomers';
import AdminLogin from './components/AdminLogin';
import AdminOrders from './components/AdminOrders';
import AdminUsers from './components/AdminUsers';
import Designer from './components/Designer';
import ForgotPassword from './components/ForgotPassword';
import Landing from './components/Landing';
import Login from './components/Login';
import MyInformation from './components/MyInformation';
import MyOrderDetails from './components/MyOrderDetails';
import MyOrders from './components/MyOrders';
import Privacy from './components/Privacy';
import ResetPassword from './components/ResetPassword';
import SignUp from './components/SignUp';
import Terms from './components/Terms';

require('dotenv').config();

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

ReactDOM.render(
    <Provider store={store}>
        <ReactNotification />
        <Router>
            <Route path="/account" exact component={Account} />
            <Route path="/activate-account/:id/:token" exact component={ActivateAccount} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/" exact component={Landing} />
            <Route path="/login" exact component={Login} />
            <Route path="/my-information" exact component={MyInformation} />
            <Route path="/my-orders/details/:id" exact component={MyOrderDetails} />
            <Route path="/my-orders" exact component={MyOrders} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/reset-password/:id/:token" exact component={ResetPassword} />
            <Route path="/sign-up" exact component={SignUp} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/designer" exact component={Designer} />

            <Route path="/admin/configuration" exact component={AdminConfiguration} />
            <Route path="/admin/customers" exact component={AdminCustomers} />
            <Route path="/admin/customers/:action" exact component={AdminCustomers} />
            <Route path="/admin/customers/:action/:id" exact component={AdminCustomers} />
            <Route path="/admin" exact component={AdminOrders} />
            <Route path="/admin/login" exact component={AdminLogin} />
            <Route path="/admin/orders" exact component={AdminOrders} />
            <Route path="/admin/orders/:action" exact component={AdminOrders} />
            <Route path="/admin/orders/:action/:id" exact component={AdminOrders} />
            <Route path="/admin/users" exact component={AdminUsers} />
            <Route path="/admin/users/:action" exact component={AdminUsers} />
            <Route path="/admin/users/:action/:id" exact component={AdminUsers} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
