import { createSelector } from 'reselect';

export const getComponentState = state => state.layout;

export const getShowSidebarMenu = createSelector(
    getComponentState,
    (state) => state.showSidebarMenu
);
export const getCartTotalItems = createSelector(
    getComponentState,
    (state) => state.cartTotalItems
);
export const getFetchingCartTotalItems = createSelector(
    getComponentState,
    (state) => state.fetchingCartTotalItems
);
export const getScrollPosition = createSelector(
    getComponentState,
    (state) => state.scrollPosition
);
export const getCheckingHelpDesk = createSelector(
    getComponentState,
    (state) => state.checkingHelpDesk
);
export const getHelpDeskItems = createSelector(
    getComponentState,
    (state) => state.helpDeskItems
);