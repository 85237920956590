import Immutable from 'immutable';
import * as types from './SignUp.types';

const initialState = {
    form: {
        email: '',
        password: '',
        confirm_password: '',
    },
    registering: false,
    history: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SET_FIELD:
            return state.setIn(['form', action.payload.name], action.payload.value).toJS();

        case types.REGISTER:
            return state.setIn(['registering'], true).toJS();

        case types.REGISTER_SUCCESS:
            return state.setIn(['registering'], false)
                .setIn(['form'], { email: '', password: '' })
                .toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.REGISTER_ERROR:
            return state.setIn(['registering'], false).toJS();

        default:
            return state.toJS();
    }
}