import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
    const path = props.history.location.pathname;

    let productsMenu = null;
    let couponsMenu = null;
    let configurationMenu = null;
    let testimonialsMenu = null;
    let usersMenu = null;
    if (parseInt(props.user.type) === 1) {  // Super Admin
        productsMenu = (
            <div className={path.includes('/admin/products') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/products">
                    <i className="fa-solid fa-database" /> Products
                </Link>
            </div>
        );
        couponsMenu = (
            <div className={path.includes('/admin/coupons') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/coupons">
                    <i className="fa-solid fa-percent" /> Coupons
                </Link>
            </div>
        );
        configurationMenu = (
            <div className={path.includes('/admin/configuration') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/configuration">
                    <i className="fa-solid fa-gears" /> Configuration
                </Link>
            </div>
        );
        testimonialsMenu = (
            <div className={path.includes('/admin/testimonials') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/testimonials">
                    <i className="fa-solid fa-comment" /> Testimonials
                </Link>
            </div>
        );
        usersMenu = (
            <div className={path.includes('/admin/users') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/users">
                    <i className="fa-solid fa-cart-shopping"></i> Users
                </Link>
            </div>
        );
    }

    return (
        <div className="sidebar-container">
            <div className={path.includes('/admin/orders') || path === '/admin' ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/orders">
                    <i className="fa-solid fa-cart-shopping"></i> Orders
                </Link>
            </div>
            <div className={path.includes('/admin/customers') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/customers">
                    <i className="fa-solid fa-users" /> Customers
                </Link>
            </div>
            {productsMenu}
            <div className={path.includes('/admin/pending-orders') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/pending-orders">
                    <i className="fa-solid fa-clock"></i> Pending Orders
                </Link>
            </div>
            {/* <div className={path.includes('/admin/feature-options') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/feature-options">
                    <i className="fa-solid fa-circle-check" /> Feature Options
                </Link>
            </div> */}
            {couponsMenu}
            {configurationMenu}
            {/* <div className={path.includes('/admin/mail-checker') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/mail-checker">
                    <i className="fa-solid fa-scale-balanced" /> Mail Size Checker
                </Link>
            </div> */}
            {/* <div className={path.includes('/admin/email-notifications') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/email-notifications">
                    <i className="fa-solid fa-envelope" /> Email Notifications
                </Link>
            </div> */}
            {testimonialsMenu}
            {/* <div className={path.includes('/admin/faqs') ? 'sidebar-item active' : 'sidebar-item'}>
                <Link to="/admin/faqs">
                    <i className="fa-solid fa-circle-question" /> FAQs
                </Link>
            </div> */}
            {usersMenu}
        </div>
    );
}

export default Sidebar;
