import React from 'react';

const Information = (props) => {
    if (props.fetchingInformation || props.information == null) {
        return (
            <div className="information-container">
                <div className="loading-container">
                    <i className="fas fa-circle-notch fa-spin" /> Loading data...
                </div>
            </div>
        );
    }

    const handleSetField = (field, event) => {
        props.setField(field, event.target.value);
    };

    return (
        <div className="information-container">
            <div className="title">My Account Information</div>
            <div className="information-fields">
                <div className="subtitle">Name</div>
                <div className="field">
                    <div className="label">First Name:</div>
                    <input type="text" value={props.information.first_name} onChange={handleSetField.bind(this, 'first_name')} />
                </div>
                <div className="field">
                    <div className="label">Last Name:</div>
                    <input type="text" value={props.information.last_name} onChange={handleSetField.bind(this, 'last_name')} />
                </div>
                <div className="field">
                    <div className="label">Company:</div>
                    <input type="text" value={props.information.company} onChange={handleSetField.bind(this, 'company')} />
                </div>
                <div className="field">
                    <div className="label">Country:</div>
                    <select value={props.information.country_id} onChange={handleSetField.bind(this, 'country_id')}>
                        <option value="">Select option</option>
                        <option value="230">United States</option>
                    </select>
                </div>
                <div className="field">
                    <div className="label">State:</div>
                    <select value={props.information.state} onChange={handleSetField.bind(this, 'state')}>
                        <option value="">Select option</option>
                        {props.states.map((state) => <option value={state.name}>{state.name}</option>)}
                    </select>
                </div>
                <div className="field">
                    <div className="label">City:</div>
                    <input type="text" value={props.information.city} onChange={handleSetField.bind(this, 'city')} />
                </div>
                <div className="button-container">
                    <button onClick={props.saveInformation} disabled={props.savingInformation}>
                        {props.savingInformation ? <i className="fas fa-circle-notch fa-spin" /> : 'SAVE'}
                    </button>
                </div>
            </div>
            <div className="password-fields">
                <div className="subtitle">Change Password</div>
                <div className="field">
                    <div className="label">Old Password:</div>
                    <input type="password" value={props.information.old_password} onChange={handleSetField.bind(this, 'old_password')} />
                </div>
                <div className="field">
                    <div className="label">New Password:</div>
                    <input type="password" value={props.information.new_password} onChange={handleSetField.bind(this, 'new_password')} />
                </div>
                <div className="field">
                    <div className="label">Confirm New Password:</div>
                    <input type="password" value={props.information.confirm_password} onChange={handleSetField.bind(this, 'confirm_password')} />
                </div>
                <div className="button-container">
                    <button onClick={props.savePassword} disabled={props.savingPassword}>
                        {props.savingPassword ? <i className="fas fa-circle-notch fa-spin" /> : 'CHANGE'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Information;
