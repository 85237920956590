import Immutable from 'immutable';
import * as types from './Layout.types';

const initialState = {
    showSidebarMenu: false,
    cartTotalItems: 0,
    fetchingCartTotalItems: false,
    checkingHelpDesk: false,
    helpDeskItems: 0,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_CART_TOTAL_ITEMS:
            return state.setIn(['fetchingCartTotalItems'], true).toJS();

        case types.FETCH_CART_TOTAL_ITEMS_SUCCESS:
            return state.setIn(['fetchingCartTotalItems'], false)
                .setIn(['cartTotalItems'], action.payload)
                .toJS();

        case types.FETCH_CART_TOTAL_ITEMS_ERROR:
            return state.setIn(['fetchingCartTotalItems'], false).toJS();

        case types.CHECK_HELP_DESK:
            return state.setIn(['checkingHelpDesk'], true).toJS();

        case types.CHECK_HELP_DESK_SUCCESS:
            return state.setIn(['checkingHelpDesk'], false)
                .setIn(['helpDeskItems'], action.payload)
                .toJS();

        case types.CHECK_HELP_DESK_ERROR:
            return state.setIn(['checkingHelpDesk'], false).toJS();

        case types.SET_SHOW_SIDEBAR_MENU:
            return state.setIn(['showSidebarMenu'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}