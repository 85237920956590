import Immutable from 'immutable';
import * as types from './MyOrderDetails.types';

const initialState = {
    order: null,
    fetchingOrder: false,
    history: null,
    idModal: null,
    typeModal: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_ORDER:
            return state.setIn(['fetchingOrder'], true).toJS();

        case types.FETCH_ORDER_SUCCESS:
            return state.setIn(['fetchingOrder'], false)
                .setIn(['order'], action.payload)
                .toJS();

        case types.FETCH_ORDER_ERROR:
            return state.setIn(['fetchingOrder'], false).toJS();

        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.SET_ID_MODAL:
            return state.setIn(['idModal'], action.payload).toJS();

        case types.SET_TYPE_MODAL:
            return state.setIn(['typeModal'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}