import React from 'react';
import { Link } from 'react-router-dom';

const Banner = (props) => {
    return (
        <div className="banner-container">
            <div className="description-container content-container">
                <div className="title">
                    Customize your world with <span>Custom Brand It</span>
                </div>
                <div className="subtitle">
                    Create unique products that reflect your personal style and make memories that last a lifetime...
                </div>
                <Link to="/" className="start-button">Start Now!</Link>
                <img src="https://ac-cbi.s3.amazonaws.com/css/home-banner.png" alt="Custom Brand It" />
            </div>
        </div>
    );
}

export default Banner;
