import axios from 'axios';
import * as types from './SignUp.types';
import * as selectors from './SignUp.selectors';
import * as loginActions from './../Login/Login.actions';
import { showNotification, empty } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const register = () => (dispatch, getState) => {
    const form = selectors.getForm(getState());
    const history = selectors.getHistory(getState());

    if (!validateSaveFields(form)) {
        return;
    }

    dispatch({ type: types.REGISTER, payload: null });
    axios({
        method: 'post',
        url: apiUrl + '/customer/sign-up',
        data: form,
    }).then((response) => {
        dispatch({ type: types.REGISTER_SUCCESS, payload: response.data });
        showNotification('Sign Up Successfully', 'Your user has been created successfully', 'success');
        dispatch(loginActions.login(history, form.email, form.password));
    }).catch((error) => {
        dispatch({ type: types.REGISTER_ERROR, payload: null });
        if (error.response && error.response.status === 409) {
            showNotification('Invalid data', 'The Email already exists', 'info');
        } else {
            showNotification('Error', 'An error has occurred!', 'danger');
        }
    });
}

export const validateSaveFields = (form) => {
    if (empty(form.first_name)) {
        showNotification('Complete Information', 'Enter the First Name', 'info');
        return false;
    }
    if (empty(form.last_name)) {
        showNotification('Complete Information', 'Enter the Last Name', 'info');
        return false;
    }
    if (empty(form.email)) {
        showNotification('Complete Information', 'Enter the Email', 'info');
        return false;
    }
    if (empty(form.password)) {
        showNotification('Complete Information', 'Enter the Password', 'info');
        return false;
    }
    if (empty(form.confirm_password)) {
        showNotification('Complete Information', 'Enter the Confirm Password', 'info');
        return false;
    }
    if (form.password !== form.confirm_password) {
        showNotification('Complete Information', 'The Password and Confirm Password are different', 'info');
        return false;
    }

    return true;
}

export const setField = (name, value) => dispatch => {
    dispatch({
        type: types.SET_FIELD,
        payload: { name, value }
    });
}

export const setHistory = (history) => dispatch => {
    dispatch({
        type: types.SET_HISTORY,
        payload: history
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}