import { fabric } from "fabric";

export const initCanvas = () => {
    var canvas = new fabric.Canvas('fabric-canvas', {
        width: 240,
        height: 260,
        backgroundColor: '#ffffff',
    });

    var text = new fabric.IText('Enter your text', { left: 30, top: 140, fontSize: 30 });
    canvas.add(text);

    fabric.Image.fromURL('https://ac-cbi.s3.amazonaws.com/logo.png', function (oImg) {
        oImg.top = 40;
        oImg.left = 5;
        canvas.add(oImg);
    });
}
