import axios from 'axios';
import * as types from './Layout.types';
import * as selectors from './Layout.selectors';
import { validateAuthInResponse } from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCartTotalItems = () => (dispatch) => {
    dispatch({ type: types.FETCH_CART_TOTAL_ITEMS, payload: null });
    axios.get(apiUrl + '/abs-shopping-cart-item/total-items',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_CART_TOTAL_ITEMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.FETCH_CART_TOTAL_ITEMS_ERROR));
        });
}

export const checkHelpDesk = () => (dispatch, getState) => {
    dispatch({ type: types.CHECK_HELP_DESK, payload: null });
    axios.get(apiUrl + '/abs-help-request/check',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.CHECK_HELP_DESK_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.CHECK_HELP_DESK_ERROR));
        });
}

export const setShowSidebarMenu = () => (dispatch, getState) => {
    const showSidebarMenu = selectors.getShowSidebarMenu(getState());

    if (!showSidebarMenu) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }

    dispatch({
        type: types.SET_SHOW_SIDEBAR_MENU,
        payload: !showSidebarMenu,
    })
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    })
}