import Immutable from 'immutable';
import * as types from './Terms.types';

const initialState = {
    history: null,
}

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.SET_HISTORY:
            return state.setIn(['history'], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}