import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './ActivateAccount.actions';
import * as selectors from './ActivateAccount.selectors';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import './ActivateAccount.scss';

const ActivateAccount = (props) => {
    /* State to props */
    const activating = useSelector(selectors.getActivating);
    const activated = useSelector(selectors.getActivated);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const activate = useCallback((id, token) => dispatch(actions.activate(id, token)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        activate(props.match.params.id, props.match.params.token);

        return () => {
            resetState();
        };
    }, [activate, resetState, props.match.params.id, props.match.params.token]);

    return (
        <div className="activate-account-container">
            <Helmet>
                <title>Activate Account - Custom Brand It</title>
            </Helmet>
            <Favicon url="https://ac-cbi.s3.amazonaws.com/favicon.ico" />
            <Form
                activating={activating}
                activated={activated}
                history={props.history}
            />
        </div>
    );
};

export default ActivateAccount;
